<template>
  <div class="settings" >
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>
    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">
      <workstation-nav></workstation-nav>
      <div class="content" :style="{ 'min-height': `${windowHeight - 110}px` }">
        <div style="padding-top: 56px;padding-bottom: 100px; margin-left: 57px;">

          <div class="top-box">
            <div style="padding:40px 30px;">

              <p v-if="mainCategory && mainCategory.id" class="header-text">Senin için en iyi ilan ve freelancer’ları bulduk!</p>
              <template v-else>
                <p  class="header-text">Senin için en iyi ilan ve freelancer’ları bulacağız!</p>
                <p class="description-text">Lütfen alt bölümünde ilgili kategori ve alt kategori seçimi yaparak başla.</p>
                <button @click="activateStartModal" class="start-button">Hadi Başla!</button>
              </template>


              <div v-if="mainCategory && mainCategory.id" class="main-cat-area" :style="mainCategory.id === 3 ? 'color:#2d3640;background-color:'+mainCategory.cat_color : 'color:white;background-color:'+mainCategory.cat_color">
                <div class="bottom-cat-img-container" :style="'background:'+categoryBackgrounds.find(({ id }) => id === mainCategory.id).bg">
                  <img class="bottom-cat-img" :src="'https://gcdn.bionluk.com/site/cicons/catImg/squares/sm_'+mainCategory.id+'.png'">
                </div>
                <div v-if="mainCategory" style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                  <div class="texts" :style="mainCategory.id !== 3 ? 'color:white' : 'color:#2d3640'">
                    <p class="texts-title">{{mainCategory.name}}</p>
                    <p class="texts-sub-title">Bu kategoride hizmet veren <span>{{mainCategory.user_count | number_format}} Freelancer</span> var.</p>
                  </div>
                  <div  v-if="mainCategory.cat_users.length" style="display: flex; margin-right: 30px;">
                    <div v-for="onlineUser in mainCategory.cat_users ">
                      <div>
                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'bia up small box')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'bia up small box')"
                          :to="'/'+onlineUser.username">
                          <img :alt="onlineUser.username" style="border:1px solid #bfc8d2; width: 44px; height: 44px; border-radius: 50%; margin-left: -15px;" :src="onlineUser.avatar_url">
                        </router-link>
                      </div>
                    </div>
                    <div style="width: 45px;height: 45px;border: solid 1px #ffffff;background-color: #eaedf2; border-radius: 50%; font-size: 14px; margin-left: -15px; display: flex; align-items: center; justify-content: center;letter-spacing: -0.43px; color: #2d3640; font-weight: bold">
                      +{{mainCategory.user_count - 5 }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="mainCategory && mainCategory.id" style="display: flex; margin-top: 40px;">
               <div style="width: 100%; max-width: 250px;">
                 <custom-select label="Ana Kategori"
                                :disabled="!pageLoaded"
                                id="mainCategory"
                                v-model="selectedMainCategory"
                                :options="parentCategories"
                                :customOption="{name: 'name', value:'id'}"
                                style="width:100%;margin-bottom: 42px;"/>
               </div>
                <div style="width: 100%;margin-left: 25px;max-width: 250px;">
                  <custom-select label="Alt Kategori"
                                 id="subCategory"
                                 :disabled="!pageLoaded"
                                 :options="mainCategory.subCategory"
                                 v-model="selectedSubCategory"
                                 :customOption="{name: 'name', value:'id'}"
                                 style="width:100%;margin-bottom: 42px;"/>
                </div>
                <div v-if="subCategory.id && subCategory.category_service_types && subCategory.category_service_types.length > 0 " style="width: 100%;margin-left: 25px; max-width: 250px;">
                  <custom-select :label="subCategory.category_service_type_title"
                                 id="serviceType"
                                 :disabled="!pageLoaded"
                                 v-model="serviceType"
                                 :options="subCategory.category_service_types"
                                 :customOption="{name: 'name', value:'id'}"
                                 style="width:100%;margin-bottom: 42px;"/>
                </div>
                <div v-if="subCategory.id && subCategory.category_service_kinds && subCategory.category_service_kinds.options && subCategory.category_service_kinds.options.length > 0 " style="width: 100%;margin-left: 25px;max-width: 250px;">
                  <custom-select :label="subCategory.category_service_kinds.title"
                                 id="serviceKind"
                                 :disabled="!pageLoaded"
                                 v-model="serviceKind"
                                 :options="subCategory.category_service_kinds.options"
                                 :customOption="{name: 'name', value:'id'}"
                                 style="width:100%;margin-bottom: 42px;"/>
                </div>
              </div>

           </div>

         </div>

          <div v-if="!pageLoaded" style="margin-top: 50px;">
            <loader ></loader>
          </div>
          <div v-else class="body-box">
            <template v-if="biaGigs.length > 0">
              <p class="title">Seçtiğin Kategoride Sana <span>En Uygun İlanlar</span> 💫</p>
              <GigBox v-if="biaGigs.length > 0" type="bia" :list="biaGigs.slice(0,3)"></GigBox>
              <div v-if="biaGigs.length > 3" style="height: 40px"></div>
              <GigBox v-if="biaGigs.length > 3" type="bia" :list="biaGigs.slice(3,6)"></GigBox>

              <p  v-if="biaUsers.length > 0" class="title">Seçtiğin Kategorinin <span>En İyi Freelancerları</span> 🥰</p>
              <FreelancerBox  v-if="biaUsers.length > 0" type="bia" :list="biaUsers.slice(0,4)"></FreelancerBox>
              <div v-if="biaUsers.length > 4" style="height: 40px"></div>
              <FreelancerBox v-if="biaUsers.length > 4" type="bia" :list="biaUsers.slice(4,8)"></FreelancerBox>
            </template>

            <div class="go-request" v-if="mainCategory && mainCategory.id && pageLoaded">
              <div class="left">
                <p class="title">Aradığın hizmeti veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!</p>
                <button

                  @click="clicked('go_to_buyer_requests')"
                  class="cuper-red-button" style="margin-top: 20px">Alıcı İsteği Oluştur</button>
              </div>
              <img class="right" src="https://gcdn.bionluk.com/site/general/il-special-requests%402x.png">
            </div>

          </div>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
 import WorkstationNav from "../../../components/shared/globalSideNav";
 import CustomSelect from "../../../components/CustomSelect";
 import CustomButton from "../../../components/CustomButton";
 import GigBox from "../../../components/boxSliders/gig";
 import FreelancerBox from "../../../components/boxSliders/freelancer";
 export default {
   name: "src-pages-body-bia-detail-v1",
   components: {
     WorkstationNav,
     CustomSelect,
     CustomButton,
     GigBox,
     FreelancerBox
   },
   data() {
     return {
       categoryBackgrounds: [
         {
           'id':1,
           'bg':'rgba(58, 89, 233,0.1)'
         },
         {
           'id':2,
           'bg':'rgba(88, 187, 211,0.2)'
         },
         {
           'id':3,
           'bg':'rgba(255, 191, 0,0.2)'
         },
         {
           'id':4,
           'bg':'rgba(63, 170, 64,0.2)'
         },
         {
           'id':5,
           'bg':'rgba(255, 76, 84,0.1)'
         },
         {
           'id':6,
           'bg':'rgba(75, 79, 82,0.2)'
         },
         {
           'id':8,
           'bg':'rgba(84, 63, 170,0.2)'
         }
       ],
       buttonLoading: false,
       selectedMainCategory: null,
       selectedSubCategory: null,
       mainCategory: {},
       subCategory: {},
       serviceKind: null,
       serviceType: null,
       pageLoaded: false,
       biaUsers: [],
       biaGigs: []
     }
   },
   methods: {
     clicked(type) {
       if(type === 'go_to_buyer_requests') {
         this.$router.push('panel/istekler')
       }
     },
     activateStartModal() {
       this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.BIA_START, info: {calledFrom:'bia'}});
     },
     getBiaInfo() {
       if (this.mainCategory.id) {
         this.api.feeds.retrieveBiaUsersV2(this.mainCategory.id, this.subCategory.id, this.serviceType, this.serviceKind, this.$Progress)
           .then(({data}) => {

             let result = data;
             if (result.success) {

               this.biaUsers = result.data.bia_users;
               this.biaGigs = result.data.bia_gigs;

             } else {
               this.$toasted.error(result.message)
             }
             this.pageLoaded = true;
           })
           .catch(err => {
             this.pageLoaded = true;
             this.$toasted.error(err);
           })
       } else {
         this.pageLoaded = true;
       }
     },
     pushRoute() {
       let url = '/benim-isim-acil?category_id='+this.mainCategory.id+'&category_sub_id='+this.subCategory.id;
       if(this.serviceType){
         url += '&service_type_id='+this.serviceType;
       }
       if (this.serviceKind) {
         url += '&service_kind_id='+this.serviceKind;
       }

       if (this.pageLoaded) {
         this.$router.push(url)
       }
     },
     async init() {
       this.pageLoaded = false;
       this.mainCategory = await this.findCategoryByID(Number(this.route.query.category_id)) || {}
       if (this.mainCategory) {
         this.selectedMainCategory = this.mainCategory.id;
         this.subCategory = await this.findCategoryByID(Number(this.route.query.category_sub_id)) || {}
         if (this.subCategory) {
           this.selectedSubCategory = this.subCategory.id;
           this.serviceKind = Number(this.route.query.service_kind_id) || null;
           this.serviceType = Number(this.route.query.service_type_id) || null;
         }
       }
       this.getBiaInfo()
     }
   },
   watch: {
     "$store.state.routerParams": function (newValue, oldValue) {
       if(newValue !== oldValue){
         this.init()
       }
     },
     async selectedMainCategory(newVal, oldVal) {
       if (newVal && oldVal && newVal !== oldVal) {
         const mainCategory = await this.findCategoryByID(newVal) || {}
         this.mainCategory = JSON.parse(JSON.stringify(mainCategory))
         this.selectedSubCategory = null
         this.serviceType = null
         this.serviceKind = null
         this.subCategory = {}
       }
     },
     serviceKind(newVal, oldVal) {
       if (newVal && newVal !== oldVal) {
         if (this.changeParams) {
           this.pushRoute()
         }
       }
     },
     serviceType(newVal, oldVal) {
       if (newVal && newVal !== oldVal) {
         if (this.changeParams) {
           this.pushRoute()
         }
       }
     },
     async selectedSubCategory(newVal, oldVal) {
       if(newVal && oldVal && newVal !== oldVal){
         const subCategory = await this.findCategoryByID(newVal) || {};
         this.subCategory = JSON.parse(JSON.stringify(subCategory));
         this.serviceType = null;
         this.serviceKind = null;
       } else if(newVal && !oldVal){
         const subCategory = await this.findCategoryByID(newVal) || {};
         this.subCategory = JSON.parse(JSON.stringify(subCategory));
       }
       if (newVal && newVal !== oldVal) {
         if (this.changeParams) {
          this.pushRoute()
         }
       }
     }
   },
   computed: {
     changeParams() {
       let changeParams = false

       if (this.subCategory.id) {
         changeParams = true
         if (this.subCategory.category_service_types && this.subCategory.category_service_types.length > 0 && !this.serviceType ) {
           changeParams = false
         }
         if (this.subCategory.category_service_kinds && this.subCategory.category_service_kinds.options && this.subCategory.category_service_kinds.options.length > 0 && !this.serviceKind) {
           changeParams = false
         }
       }
       return changeParams
     }
   },
   created() {
     this.init()
   }
 }

</script>


<style scoped lang="scss">


  .go-request{
    background: url("https://gcdn.bionluk.com/site/general/sl_bg%402x.png");
    background-size: cover;
    width:890px;
    height: 224px;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      margin-left: 50px;
      .title{
        margin-top: 0;
        width: 400px;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.88;
        color: #ffffff;
      }
      .cuper-red-button{
        width: 200px;
        height: 44px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
      }

    }

    .right{
      margin-right: 60px;
      width: 335px;
      height: 203px;
    }

  }


  .buyer-request-box{
    margin-top: 50px;
    width: 893px;
    height: 196px;

    background-image: url("https://gcdn.bionluk.com/site/general/bia_buyer_req_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    flex-direction: column;

    .description{
      width: 718px;
      height: 52px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }

  .body-box{

    width: 896px;
    .title{
      margin-top: 50px;
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 500;
      color: #2d3640;
      span{
        font-weight: bold;
      }
    }
  }

  .bottom-cat-img-container{
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 55px;
    height: 55px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    .bottom-cat-img{
      position: absolute;
      margin-top: 15px;
      margin-left: 10px;
      width: 43px!important;
      height: 43px!important;
    }
  }

  .main-cat-area{
    width: 793px;
    height: 90px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;


    .texts{
      margin-left:33px;display: flex;
      flex-direction: column;

      .texts-title{
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;

      }

      .texts-sub-title{
        margin-top: 5px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;

        span{
          font-weight: bold;
        }
      }
    }
  }
 .top-box{

   width: 893px;
   height: 315px;
   object-fit: contain;
   border-radius: 10px;
   box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
   border: solid 1px #eaedf2;
   background-color: #ffffff;

   .start-button{
     margin: 50px auto;
     width: 250px;
     height: 56px;
     background: linear-gradient(to right, #e63262 0%, #fd4056 100%);
     font-size: 20px;
     font-weight: 600;
     letter-spacing: -0.45px;
     color: #ffffff;
     display: flex;
     align-items: center;
     justify-content: center;
   }
   .header-text{
     font-size: 22px;
     font-weight: bold;
     line-height: 1.18;
     color: #2d3640;
   }

   .description-text{
     font-size: 18px;
     font-weight: normal;
     margin-top: 15px;
     line-height: 1.44;

     color: #5e6b79;
   }
 }
</style>
